import React, { useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { BorderColor } from '@mui/icons-material';
import Classes from './SectionC.module.css';
import { AnimateTopToBottom } from './Animations/AnimateTopToBottom';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { useNavigate } from 'react-router-dom';

const DeckAnalyzerIcon = (props) => (
  <svg
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    id="fi_1151511"
    {...props} // Allows additional props to be passed
  >
    <path d="m351.332031 153.660156v-88.453125c0-35.953125-29.253906-65.207031-65.210937-65.207031h-220.917969c-35.953125 0-65.203125 29.253906-65.203125 65.207031v147.714844c0 30.796875 21.457031 56.671875 50.203125 63.46875v36.949219c0 6.066406 3.65625 11.535156 9.261719 13.859375 1.855468.769531 3.804687 1.140625 5.738281 1.140625 3.902344 0 7.738281-1.523438 10.609375-4.394532l45.8125-45.8125h9.046875v-29.265624c0-52.5 42.707031-95.207032 95.203125-95.207032zm0 0"></path>
    <path d="m446.792969 183.660156h-220.917969c-35.953125 0-65.203125 29.25-65.203125 65.207032v147.714843c0 35.957031 29.25 65.207031 65.203125 65.207031h164.496094l45.8125 45.816407c2.871094 2.871093 6.707031 4.394531 10.613281 4.394531 1.929687 0 3.878906-.371094 5.734375-1.140625 5.605469-2.324219 9.261719-7.792969 9.261719-13.859375v-36.949219c28.75-6.792969 50.207031-32.671875 50.207031-63.46875v-147.714843c0-35.957032-29.253906-65.207032-65.207031-65.207032zm-46.597657 114.167969-71.007812 71.007813c-2.929688 2.929687-6.769531 4.390624-10.605469 4.390624-3.839843 0-7.679687-1.460937-10.605469-4.390624l-35.507812-35.503907c-5.859375-5.855469-5.859375-15.355469 0-21.214843 5.855469-5.855469 15.355469-5.855469 21.210938 0l24.902343 24.898437 60.398438-60.402344c5.859375-5.855469 15.355469-5.855469 21.214843 0 5.859376 5.859375 5.859376 15.359375 0 21.214844zm0 0"></path>
  </svg>
);

const ScoringIcon = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" /></svg>
  )
}

const Pipeline = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="fi_17128490">
      <g id="Layer">
        <path d="M13.32,24.94A.87.87,0,0,1,13,25H8a1,1,0,0,1,0-2h5c0-.17,0-.33,0-.5A9.48,9.48,0,0,1,14.75,17H8a1,1,0,0,1,0-2h8.67a9.43,9.43,0,0,1,5.83-2,9.29,9.29,0,0,1,1.5.12V5a3,3,0,0,0-3-3H5A3,3,0,0,0,2,5V27a3,3,0,0,0,3,3H16.67A9.53,9.53,0,0,1,13.32,24.94ZM8,7H18a1,1,0,0,1,0,2H8A1,1,0,0,1,8,7Zm0,4H18a1,1,0,0,1,0,2H8a1,1,0,0,1,0-2Zm14.5,4A7.5,7.5,0,1,0,30,22.5,7.5,7.5,0,0,0,22.5,15ZM22,21.6l1.45.34A2.19,2.19,0,0,1,25,24.39a2.6,2.6,0,0,1-1.79,2.07v0a.75.75,0,0,1-1.5,0v0a2.58,2.58,0,0,1-1.82-2,.75.75,0,1,1,1.47-.28,1.1,1.1,0,0,0,2.16,0,.68.68,0,0,0-.51-.76l-1.45-.34A2.19,2.19,0,0,1,20,20.61a2.6,2.6,0,0,1,1.79-2.07v0a.75.75,0,0,1,1.5,0v0a2.58,2.58,0,0,1,1.82,2,.75.75,0,1,1-1.47.28,1.1,1.1,0,0,0-2.16,0A.68.68,0,0,0,22,21.6Z" />
      </g>
    </svg>
  );
};

// const screeningDashboardIcon = (props) => (
// );

const SectionC = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const sectionRef = useRef(null); // Reference to the section

  const navigate = useNavigate();

  const navigateToSignup = () => {
    navigate('/login', { state: { data: "toSignup" } });
  }

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  const [expanded, setExpanded] = useState('panel1');
  // const [expanded, setExpanded] = useState(() => {
  //   return localStorage.getItem('expandedPanel') || 'panel1';
  // });

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(panel);
    localStorage.setItem('expandedPanel', panel);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Add the animation class when the section is in the viewport
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-on-scroll-c');
        }
      },
      {
        root: null, // relative to the viewport
        rootMargin: '0px',
        threshold: 0.1, // trigger the callback when 10% of the element is visible
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section id='features' className={Classes.howItWorks} ref={sectionRef} >
      <div className={Classes.card} >
        <div className={Classes.cardLeft} >
          <h1 className={Classes.subheading}> Leverage AI to save you time and money. </h1>
          <p className={Classes.text}>
            Screen companies with higher quality than human experts, all in a fraction of the time and money.
          </p>
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}
            sx={{
              boxShadow: 'none !important',
              border: 'none !important',
              borderRadius: '10px !important',
              marginBottom: '10px !important',
              '&:before': {
                display: 'none',
              }
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
              sx={{
                minHeight: '32px !important',
                margin: '0 !important',
                padding: '0px 16px !important',
                '&.Mui-expanded': {
                  margin: '0 !important',
                  minHeight: '32px !important',
                  padding: '0px 16px !important',
                },
                '.MuiAccordionSummary-content': {
                  margin: '10px 0 !important'
                },
              }}
            >
              <div className={Classes.AccordionHeader} >
                <div className={Classes.miniBox} >
                  {/* <LazyLoadImage
                    effect="opacity" src='/icons/brush-white.png' alt='brush icon' /> */}
                  <svg
                    id="fi_10337003"
                    enableBackground="new 0 0 512 512"
                    viewBox="0 0 512 512"
                    xmlns="http://www.w3.org/2000/svg"
                    height='18px'
                    fill='white'
                  >
                    <g id="Layer_2_00000124881960921214502080000013090863086384764563_">
                      <g id="paintbrush">
                        <path d="m512 13.8c-1.1 3.1-2.1 8.6-4.8 13.2-10.8 18.4-21.2 37.2-33.4 54.8-38.7 55.9-81.8 108.3-126.1 159.7-29.1 33.8-58.3 67.7-91.9 97.2-14.3 12.6-29.9 23.8-45 35.5-4.4 3.4-6.5 2.9-9-2-14.1-27.6-35.3-48.1-63-62-5.1-2.5-5.3-4.2-1.7-9.2 22.2-31.1 48.3-58.8 76.8-84.2 71.7-63.8 146-124.2 225.6-178 16.2-10.8 33.5-19.8 50.4-29.2 4.5-2.5 9.9-3.6 15-4.5 4.7-.9 7.1 1.7 7.1 8.7z"></path>
                        <path d="m22.1 507.2c-3.1-.4-9-.8-14.7-2.2-8.4-2.1-9.4-6.5-4.2-13.3 5.6-7.3 11.4-14.5 16.4-22.2 11.6-18 16-38.4 18.9-59.2 1.2-9.1 2.4-18.4 5.2-27.1 7.1-22.8 20.8-39.9 44.8-46.5 9.1-2.5 18.8-2.3 27.8.6 29.4 9.1 50 28.2 60.5 57.1 8 22.1 2.6 41.8-14.6 58-19.9 18.7-43.4 31.3-68.9 40.5-22.8 8.6-46.9 13.4-71.2 14.3z"></path>
                      </g>
                    </g>
                  </svg>
                </div>
                <h5>Custom Screening Tool</h5>
              </div>
            </AccordionSummary>
            <AccordionDetails className={Classes.accordinBody}
              sx={{
                padding: '8px 40px 16px 64px !important'
              }}
            >
              <h5 className={Classes.accordionText} > Create your screening tool in minutes with your own branding and a custom trained AI. </h5>
              <h5 className={Classes.colored} onClick={navigateToSignup} >{`Get started for free >`}</h5>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}
            sx={{
              boxShadow: 'none !important',
              border: 'none !important',
              borderRadius: '10px !important',
              marginBottom: '10px !important',
              '&:before': {
                display: 'none',
              }
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
              sx={{
                minHeight: '32px !important',
                margin: '0 !important',
                padding: '0px 16px !important',
                '&.Mui-expanded': {
                  margin: '0 !important',
                  minHeight: '32px !important',
                  padding: '0px 16px !important',
                },
                '.MuiAccordionSummary-content': {
                  margin: '10px 0 !important'
                },
              }}
            >
              <div className={Classes.AccordionHeader} >
                <div className={Classes.miniBox} >
                  {/* <LazyLoadImage
                    effect="opacity" src='/icons/deck-analyzpxer-white.png' alt='brush icon' /> */}
                  <DeckAnalyzerIcon style={{ height: '18px', fill: 'white' }} />
                </div>
                <h5>Pitch Deck Analyzer</h5>
              </div>
            </AccordionSummary>
            <AccordionDetails className={Classes.accordinBody}
              sx={{
                padding: '8px 40px 16px 64px !important'
              }}
            >
              <h5 className={Classes.accordionText}> Upload decks and receive slide-by-slide feedback and suggestions, a summary, and an overall score. </h5>
              <h5 className={Classes.colored} onClick={navigateToSignup} >{`Get started for free >`}</h5>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}
            sx={{
              boxShadow: 'none !important',
              border: 'none !important',
              borderRadius: '10px !important',
              marginBottom: '10px !important',
              '&:before': {
                display: 'none',
              }
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
              sx={{
                minHeight: '32px !important',
                margin: '0 !important',
                padding: '0px 16px !important',
                '&.Mui-expanded': {
                  margin: '0 !important',
                  minHeight: '32px !important',
                  padding: '0px 16px !important',
                },
                '.MuiAccordionSummary-content': {
                  margin: '10px 0 !important'
                },
              }}
            >
              <div className={Classes.AccordionHeader} >
                <div className={Classes.miniBox} >
                  {/* <LazyLoadImage
                    effect="opacity" src='/icons/screening-white.png' alt='brush icon' /> */}
                  <svg height='20px' fill='white' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" id="fi_4947880"><g id="Layer_4" data-name="Layer 4"><rect height="42" rx="2" width="78.97" x="10.51" y="39.44"></rect><path d="m20 33.26 60 .35a2 2 0 0 0 0-4l-60-.35a2 2 0 0 0 0 4z"></path><path d="m29.48 22.56 41 .35a2 2 0 0 0 0-4l-41-.35a2 2 0 0 0 0 4z"></path></g></svg>
                </div>
                <h5>Screening Dashboard</h5>
              </div>
            </AccordionSummary>
            <AccordionDetails className={Classes.accordinBody}
              sx={{
                padding: '8px 40px 16px 64px !important'
              }}
            >
              <h5 className={Classes.accordionText} > Filter submitted pitch decks by overall score, industry, and company description. </h5>
              <h5 className={Classes.colored} onClick={navigateToSignup} >{`Get started for free >`}</h5>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}
            sx={{
              boxShadow: 'none !important',
              border: 'none !important',
              borderRadius: '10px !important',
              marginBottom: '10px !important',
              '&:before': {
                display: 'none',
              }
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
              sx={{
                minHeight: '32px !important',
                margin: '0 !important',
                padding: '0px 16px !important',
                '&.Mui-expanded': {
                  margin: '0 !important',
                  minHeight: '32px !important',
                  padding: '0px 16px !important',
                },
                '.MuiAccordionSummary-content': {
                  margin: '10px 0 !important'
                },
              }}
            >
              <div className={Classes.AccordionHeader} >
                <div className={Classes.miniBox} >
                  <ScoringIcon height="17px" fill="white" />
                </div>
                <h5>Personalized Scoring</h5>
              </div>
            </AccordionSummary>
            <AccordionDetails className={Classes.accordinBody}
              sx={{
                padding: '8px 40px 16px 64px !important'
              }}
            >
              <h5 className={Classes.accordionText} > Invite admins or scorers to evaluate companies, allowing them to provide feedback and ratings. </h5>
              <h5 className={Classes.colored} onClick={navigateToSignup} >{`Get started for free >`}</h5>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}
            sx={{
              boxShadow: 'none !important',
              border: 'none !important',
              borderRadius: '10px !important',
              marginBottom: '10px !important',
              '&:before': {
                display: 'none',
              }
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
              sx={{
                minHeight: '32px !important',
                margin: '0 !important',
                padding: '0px 16px !important',
                '&.Mui-expanded': {
                  margin: '0 !important',
                  minHeight: '32px !important',
                  padding: '0px 16px !important',
                },
                '.MuiAccordionSummary-content': {
                  margin: '10px 0 !important'
                },
              }}
            >
              <div className={Classes.AccordionHeader} >
                <div className={Classes.miniBox} >
                  <Pipeline height="18px" fill="white" />
                </div>
                <h5>Pipeline</h5>
              </div>
            </AccordionSummary>
            <AccordionDetails className={Classes.accordinBody}
              sx={{
                padding: '8px 40px 16px 64px !important'
              }}
            >
              <h5 className={Classes.accordionText} > Organize and manage your pipeline by categorizing companies, requesting information, and reviewing key metrics. </h5>
              <h5 className={Classes.colored} onClick={navigateToSignup} >{`Get started for free >`}</h5>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className={Classes.cardRight} >
          <div className={Classes.bgBlue} >
            <span>
              {expanded === 'panel1' &&
                <LazyLoadImage effect="opacity" src='/customize.png' alt='image1' />
              }
              {expanded === 'panel2' &&
                <LazyLoadImage effect="opacity" src='/customize2.png' alt='image2' />
              }
              {expanded === 'panel3' &&
                <LazyLoadImage effect="opacity" src='/customize3.png' alt='image3' />
              }
              {expanded === 'panel4' &&
                <LazyLoadImage effect="opacity" src='/customize4.png' alt='image4' />
              }
              {expanded === 'panel5' &&
                <LazyLoadImage effect="opacity" src='/customize5.png' alt='image5' />
              }
            </span>
          </div>
        </div>
      </div>
    </section >
  );
};

export default SectionC;
