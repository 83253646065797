import React, { useState } from 'react'
import Classes from './styles/style.module.css'
import { AnimateTopToBottom } from '../Home-New/Components/Animations/AnimateTopToBottom'

function TermsOfService() {

    return (
        <>
            <form className={Classes.hero} >
                <AnimateTopToBottom delay={0.3}>
                    <h1>Terms of service</h1>
                </AnimateTopToBottom>
                <div className={Classes.form_con} >
                    <AnimateTopToBottom delay={0.33}>
                        <h5>
                            Last updated on June 17th, 2024
                        </h5>
                        <br />

                        <h5>
                            Below we have listed important legal terms that apply to anyone who visits our website or uses our platform for the purposes of evaluating a pitch deck.
                        </h5>
                        <br />

                        <h5>
                            We understand that legal terms can be exhausting to read, and we’ve tried to make the experience more pleasant. If you have any questions, please contact us at <a href='/contact-us' >  support@evala.ai, </a>.
                        </h5>
                        <br />
                    </AnimateTopToBottom>
                    <AnimateTopToBottom delay={0.2}>
                        <h5 className={Classes.major_title}> 1. LEGAL AGREEMENT </h5>
                        <ol>
                            <li>
                                <h5>
                                    1.These Terms of Use (“Terms of Use” or “Terms” or “Agreement” or “Evala Terms”), together with such additional terms which specifically apply to some of our services and features as presented on the Evala.ai website(s) (“Evala Website”, and collectively – the “Evala Terms”), all set forth the entire terms and conditions applicable to each visitor or user (“User” our “you”) of the Evala Website, the Evala mobile application (the “Evala App”) and/or any other services, applications and features offered by us with respect thereto, except where we explicitly state otherwise (all services offered through the Evala Website or the Evala App, collectively – the “Evala Services” or “Services”).
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    2.The Evala Terms constitute a binding and enforceable legal contract between Evala.ai and its affiliated companies and subsidiaries worldwide (“Evala”, “us” or “we”) and you in relation to the use of any Evala Services.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    3.You may visit and/or use the Evala Services and/or the Evala App only if you fully agree to the Evala Terms - and by using and/or registering to any of the Evala Services, you signify and affirm your informed consent to these Terms of Use and any other Evala Terms applicable to your use of any Evala Services. If you do not read, fully understand and agree to the Evala Terms, you must immediately leave the Evala Website and avoid or discontinue all use of the Evala Services.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    4.By using our Services, you acknowledge that you have read our Privacy Policy available at <a href='/privacy-policy' target='_blank' > www.Evala.ai/privacy-policy/ </a> (“Privacy Policy”).
                                </h5>
                            </li>
                        </ol>
                        <br />
                    </AnimateTopToBottom>
                    <AnimateTopToBottom delay={0.2}>

                        <h5 className={Classes.mini_title}> 1.1 User account </h5>
                        <ol>
                            <li>
                                <h5>
                                    In order to access and use certain sections and features of the Evala Services, you may be asked to first create an account with Evala (“User Account”).
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    If anyone other than yourself accesses your User Account and/or any of your Services’ settings, they may perform any actions available to you, make changes to your Services and User Account, and accept any legal terms available therein, make various representations and warranties and more – and all such activities will be deemed to have occurred on your behalf and in your name.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    Your User Account may only be used by you and may not be shared with any other individual.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    You may only use the Evala Services for one business/company/start-up. The Services are not designed for agencies or agency-type use wherein multiple uploads of different businesses/companies/start-ups are made.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    You shall abide by the limit of 10 uploads per month. Every time a file is reviewed by the Evala Services, this shall constitute an “upload”.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    Any use of the Evala Services outside of what is permissible under this Section may result in a temporary or permanent suspension of your access to the Evala Services without notice and without liability to Evala and without a right to refund.
                                </h5>
                            </li>
                        </ol>
                    </AnimateTopToBottom>
                    <br />
                    <AnimateTopToBottom delay={0.2}>

                        <h5 className={Classes.major_title}> 2. YOUR OBLIGATIONS </h5>
                        <br />

                        <h5 className={Classes.mini_title}> 2.1. You represent and warrant that: </h5>
                        <ol>
                            <li>
                                <h5>
                                    you are at least thirteen (13) years of age, or sixteen (16) years of age if you are an individual within the European Union (EU), or of the legal age of majority in your jurisdiction, and possess the legal authority, right and freedom to enter into the Evala Terms and to form a binding agreement, for yourself or on behalf of the person or entity committed by you to the Evala Terms.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    you understand that Evala does not provide any legal advice or any recommendation with respect to any laws or requirements applicable to your use, or your compliance therewith;
                                </h5>
                            </li>
                        </ol>
                        <br />
                    </AnimateTopToBottom>
                    <AnimateTopToBottom delay={0.2}>

                        <h5 className={Classes.mini_title}> 2.2. And specifically regarding your User Content: </h5>
                        <ol>
                            <li>
                                <h5>
                                    you confirm you own all rights in and to any content uploaded or provided by you, or imported, copied or uploaded by Evala Services for you (“User Content”), including any designs, images, animations, videos, audio files, fonts, logos, code, illustrations, compositions, artworks, interfaces, usernames, information you provide for the purpose of creating a subdomain name, text, literary works and any other materials (“Content”), or otherwise have (and will continue to have) the full power, title, licenses, consents and authority, in and to the User Content, as necessary to legally access to, import, copy, use, publish, transfer or license such User Content, by you and us or any of our affiliates.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    you have (and will maintain) the full power, title, licenses, consents and authority to allow Evala Services to access any websites, web pages and/or other online services, for the purpose of importing, copying, displaying, uploading, transmitting and/or otherwise using, your User Content.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    the User Content is (and will continue to be) non-infringing upon any third party rights, and in no way unlawful for you to upload, import, copy, possess, post, transmit, display or otherwise use, in the country in which you reside, or for Evala to access, import, copy, upload, use or possess in connection with the Evala Services.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    you have obtained all consents and permissions required under all applicable laws, regarding the posting, transmission and publication of any personal information and/or image or likeness of any person, entity or property which is part of the User Content, and you will adhere to all laws applicable thereto.
                                </h5>
                            </li>
                        </ol>
                        <br />
                    </AnimateTopToBottom>
                    <AnimateTopToBottom delay={0.2}>

                        <h5 className={Classes.major_title}> 2.3. You undertake and agree to: </h5>
                        <br />
                        <ol>
                            <li>
                                <h5>
                                    fully comply with all applicable laws and any other contractual terms which govern your use of the Evala Services (and any related interaction or transaction).
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    be solely responsible and liable with respect to any of the uses of the Evala Services which occur under your User Account, and for any of your User Content (including for any consequences of accessing, importing, uploading, copying, using or publishing such User Content on or with respect to the Evala Services).
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    receive from time to time promotional messages and materials from Evala or its partners, by mail, e-mail or any other contact form you may provide us with (including your phone number for calls or text messages). If you wish not to receive such promotional materials or notices – please just notify us at any time.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    Evala’s sole discretion as to the means, manner, and method for performing the Evala Services, including those regarding the hosting, transmission, publication and/or display of any Content (including the inclusion and presentation of any advertisements or other commercial content with respect thereto).
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    Evala shall have the right to offer the Evala Services in alternative price plans and impose different restrictions as for the upload, storage, download and use of the Evala Services, including, without limitation, restrictions on network traffic and bandwidth, size and/or length of Content, quality and/or format of Content, sources of Content, volume of download time, number of subscribers to your Content, etc.
                                </h5>
                            </li>
                        </ol>
                    </AnimateTopToBottom>
                    <br />
                    <AnimateTopToBottom delay={0.2}>

                        <h5 className={Classes.mini_title}> 2.4. You agree and undertake not to: </h5>
                        <ol>
                            <li>
                                <h5>
                                    copy, modify, create derivative works of, download, adapt, reverse engineer, emulate, migrate to another service, translate, compile, decompile or disassemble the Evala Website, the Evala Services (or any part thereof), any Content offered by Evala or Third Party Services for use and display within User Platforms (“Licensed Content”) and/or any part thereof in any way, or publicly display, perform, transmit or distribute any of the foregoing without Evala’s prior written and specific consent and/or as expressly permitted under the Evala Terms.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    submit, transmit or display any User Content, or use Licensed Content in a context, which may be deemed as defamatory, libelous, obscene, harassing, threatening, incendiary, abusive, racist, offensive, deceptive or fraudulent, encouraging criminal or harmful conduct, or which otherwise violates the rights of Evala or any third party (including any intellectual property rights, privacy rights, contractual or fiduciary rights), or otherwise shows any person, entity or brand in a bad or disparaging light, without their prior explicit approval.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    use any illegal action to collect login data and/or passwords for other websites, third parties, software or services.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    phish, collect, upload, or otherwise make available credit card information or other forms of financial data used for collecting payments, unless done in accordance with any applicable law, including, with the PCI DSS standard when applicable.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    upload, insert, collect or otherwise make available within the Evala Website or the Evala Services (or any part thereof), any malicious, unlawful, defamatory or obscene Content.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    publish and/or make any use of the Evala Services or Licensed Content on any website, media, network or system other than those provided by Evala, and/or frame, “deep link”, “page scrape”, mirror and/or create a browser or border environment around any of the Evala Services, Licensed Content and/or User Platform (or any part thereof), except as expressly permitted by Evala, in advance and in writing.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    use any “robot”, “spider” or other automatic device, program, script, algorithm, or methodology, or any similar or equivalent manual process, to access, acquire, copy, or monitor any portion of the Evala Services (or its data and/or Content), or in any way reproduce or circumvent the navigational structure or presentation of any of the Evala Services to obtain or attempt to obtain any materials, documents, services or information through any means not purposely made available through the Evala Services.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    act in a manner which might be perceived as damaging to Evala’s reputation and goodwill or which may bring Evala into disrepute or harm.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    purchase search engine or other pay per click keywords (such as Google AdWords), or domain names that use Evala or Evala Marks and/or variations and misspellings thereof.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    impersonate any person or entity or provide false information on the Evala Services and/or User Platform, whether directly or indirectly, or otherwise perform any manipulation in order to disguise your identity or the origin of any message or transmittal you send to Evala.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    falsely state or otherwise misrepresent your affiliation with any person or entity, or falsely express or imply that Evala or any third party endorses you, your User Platform, your business, your User Products, or any statement you make.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    reverse look-up, trace, or seek to trace another User of Evala Services, or otherwise interfere with or violate any other User’s right to privacy or other rights, or harvest or collect personally identifiable information about visitors or users of the Evala Services and/or User Platform without their express and informed consent.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    disable, circumvent, bypass or otherwise avoid any measures used to prevent or restrict access to the Evala Services, User Platform, the account of another User(s), or any other systems or networks connected to the Evala Services, by hacking, password mining, or other illegitimate or prohibited means.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    probe, scan, or test the vulnerability of the Evala Services or any network connected to the Evala Services.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    upload to the Evala Services and/or User Platform or otherwise use them to design, develop, distribute and/or otherwise transmit or execute, any virus, worm, Trojan Horse, time bomb, web bug, spyware, malware, or any other computer code, file, or program that may or is intended to damage or hijack the operation of any hardware, software, or telecommunications equipment, or any other actually or potentially harmful, disruptive, or invasive code or component.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    take any action that imposes an unreasonable or disproportionately large load on the infrastructure of the Evala Services or Evala’s systems or networks connected to the Evala Services, or otherwise interfere with or disrupt the operation of any of the Evala Services, or the servers or networks that host them or make them available, or disobey any requirements, procedures, policies, or regulations of such servers or networks.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    use any of the Evala Services and/or User Platform in connection with any form of spam, unsolicited mail, fraud, scam, phishing, “chain letters”, “pyramid schemes” or similar conduct, or otherwise engage in unethical marketing or advertising.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    access to Evala Services, User Accounts, Licensed Content and/or User Content, through any means or technology (e.g. scraping and crawling), other than our publicly supported interfaces.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    sell, license, or exploit for any commercial purposes any use of or access to the Licensed Content and/or Evala Services, except as expressly permitted by the Evala Terms.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    remove or alter any copyright notices, watermarks, restrictions and signs indicating proprietary rights of any of our licensors, including copyright mark [©], Creative Commons [(cc)] indicators, or trademarks [® or ™] contained in or accompanying the Evala Services and/or Licensed Content. or
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    violate, attempt to violate, or otherwise fail to comply with any of the Evala Terms or any laws or requirements applicable to your use of the Evala Services.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    access or use the Services for benchmarking or similar competitive analysis purposes or in order to build a competitive product or service.
                                </h5>
                            </li>
                        </ol>
                    </AnimateTopToBottom>
                    <br />
                    <AnimateTopToBottom delay={0.2}>

                        <h5>
                            You acknowledge and agree that your failure to abide by any of the foregoing or any misrepresentation made by you herein may result in the immediate termination of your User Account and/or any Services provided to you – with or without further notice to you, and without any refund of amounts paid on account of any such Services.
                        </h5>
                        <br />

                        <h5 className={Classes.mini_title}> 3. CONTENT AND OWNERSHIP </h5>
                        <br />
                        <h5 className={Classes.mini_title}> 3.1. Your Intellectual Property </h5>
                        <br />
                        <h5>
                            As between Evala and you, you shall own all intellectual property pertaining to your User Content and to any other materials created by you, including to any designs, images, animations, videos, audio files, fonts, logos, illustrations, compositions, artworks, code, interfaces, text and literary works. Evala does not claim ownership rights on your content. For the sole purpose of granting you the service, You agree that we will need to access, upload and/or copy your User Content to our platform, including cloud services and CDN’s, to make display adjustments, to duplicate for backup and perform any other technical actions and/or uses required to perform our services, as we deem fit.
                        </h5>
                        <br />
                    </AnimateTopToBottom>
                    <AnimateTopToBottom delay={0.2}>

                        <h5 className={Classes.mini_title}> 3.2. Evala’s Intellectual Property </h5>
                        <ol>
                            <li>
                                <h5>
                                    All rights, title and interest in and to the Evala Services, including any and all copyrightable materials or any other content thereof which is or may be subject to any intellectual property rights under any applicable law (including any artwork, graphics, images, website templates and widgets, literary work, source and object code, computer code (including html), applications, audio, music, video and other media, designs, animations, interfaces, documentation, derivatives and versions thereof, the “look and feel” of the Evala Services, methods, products, algorithms, data, interactive features and objects, advertising and acquisition tools and methods, inventions, trade secrets, logos, domains, customized URLs, trademarks, service marks, trade names and other proprietary identifiers, whether or not registered and/or capable of being registered (collectively, “Intellectual Property”), and any derivations thereof, are owned by and/or licensed to Evala.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    Subject to your full compliance with the Evala Terms and timely payment of all applicable Fees,  if any, Evala hereby grants you, upon creating your User Account and for as long as Evala wishes to provide you with the Evala Services, a non-exclusive, non-transferable, non-sublicensable, fully revocable, limited license to use the Evala Services and Licensed Content, solely as expressly permitted under the Evala Terms, and solely within the Evala Services.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    The Evala Terms do not convey any right or interest in or to Evala’s Intellectual Property (or any part thereof), except only for the limited license expressly granted above. Nothing in the Evala Terms constitutes an assignment or waiver of Evala’s Intellectual Property rights under any law.
                                </h5>
                            </li>
                        </ol>
                        <br />
                    </AnimateTopToBottom>
                    <AnimateTopToBottom delay={0.2}>

                        <h5 className={Classes.mini_title}> 3.3. Feedback and Suggestions </h5>
                        <ol>
                            <li>
                                <h5>
                                    If you provide us with any suggestions, comments or other feedback relating to the Evala Services (whether existing, suggested or contemplated), which is or may be subject to any Intellectual Property rights (“Feedback”), such Feedback shall be exclusively owned by Evala. By providing such Feedback to Evala, you acknowledge and agree that it may be used by Evala in order to: (i) further develop, customize and improve of the Evala Services, (ii) provide ongoing assistance and technical support, (iii) contact you with general or personalized Evala -related notices and/or interview requests based on your feedback or otherwise, (iv) facilitate, sponsor and offer certain promotions, and monitor performance, (v) to create aggregated statistical data and other aggregated and/or inferred information, which Evala may use to provide and improve its services, (vi) to enhance Evala data security and fraud prevention capabilities, and (vii) to comply with any applicable laws and regulations. In addition, you (1) represent and warrant that such Feedback is accurate, complete, and does not infringe on any third party rights; (2) irrevocably assign to Evala any right, title and interest you may have in such Feedback and (3) explicitly and irrevocably waive any and all claims relating to any past, present or future moral rights, artists’ rights, or any other similar rights worldwide in or to such Feedback.
                                </h5>
                            </li>
                        </ol>
                        <br />
                    </AnimateTopToBottom>
                    <AnimateTopToBottom delay={0.2}>

                        <h5 className={Classes.major_title}> 4. PRIVACY </h5>
                        <br />
                        <h5>
                            Certain parts of the Evala Services (including certain Third Party Services available therein, as further explained below) require or involve the submission, collection and/or use of certain personally identifying or identifiable information. We encourage you to read our Privacy Policy and each such Third Party Services’ relevant policies on a regular basis, for a description of such data collection and use practices.
                        </h5>
                        <br />

                        <h5 className={Classes.major_title}> 5. SERVICE FEES </h5>
                        <br />
                    </AnimateTopToBottom>
                    <AnimateTopToBottom delay={0.2}>

                        <h5 className={Classes.major_title}> 5.1. Paid Services </h5>
                        <ol>
                            <li>
                                <h5>
                                    The use of certain Evala Services may be subject to payment of particular fees, as determined by Evala in its sole discretion (“Paid Services” and “Fee(s)”, respectively). Evala will provide notice of such Fees then in effect in relation to such Paid Services. If you wish to receive or use such Paid Services, you are required to pay all applicable Fees in advance.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    Evala reserves the right to change its Fees at any time, upon notice to you if such change may affect your existing subscriptions. If you received a discount or other promotional offer, Evala shall have the right to automatically and without notice renew your subscription to such Evala Service(s) at the full applicable Fee.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    All Fees shall be deemed to be in U.S. Dollars, except as specifically stated otherwise in writing by Evala. To the extent permitted by law (and unless specified otherwise by Evala in writing), all Fees are exclusive of all taxes (including value added tax, sales tax, goods and services tax, etc.), levies or duties imposed by taxing authorities (“Taxes”), and you shall be responsible for payment of all applicable Taxes relating to your use of the Evala Services, or to any payments or purchases made by you. If Evala is obligated to collect or pay Taxes for the Fees payable by you, and whether or not such Taxes were added and collected from you for previous transactions, such Taxes may be added to the payment of any outstanding Fees and will be reflected in the Invoice for such transaction. We recommend that you verify the existence of any additional fees you may be charged by third parties in connection with the purchase of Paid Services or in connection with the renewal thereof (such as international transaction fees, currency exchange fees or fees due to banks or credit card companies). Evala is not responsible for any such additional fees or costs.
                                </h5>
                            </li>
                        </ol>
                        <br />
                    </AnimateTopToBottom>
                    <AnimateTopToBottom delay={0.2}>

                        <h5 className={Classes.mini_title}> 5.2. Invoices </h5>
                        <br />
                        <h5>
                            Evala and/or its affiliated companies may issue an invoice or credit memo for any payment of Fees or refund made to or by Evala (“Invoice”). Each Invoice will be issued in electronic form and based on the country stated in your billing address, and will be made available to you via your User Account and/or by e-mail. For the purpose of issuing the Invoice, you may be required to furnish certain Personal Information (as such term is defined in the Privacy Policy) in order to comply with local laws. Please note that the Invoice presented in your User Account may be inadequate with your local law requirements, and in such case may be used for pro forma purposes only.
                        </h5>
                        <br />
                    </AnimateTopToBottom>
                    <AnimateTopToBottom delay={0.2}>

                        <h5 className={Classes.mini_title} > 5.3. Subscription Auto-Renewals </h5>
                        <ol>
                            <li>
                                <h5>
                                    In order to ensure that you do not experience any interruption or loss of services, certain Paid Services include an automatic renewal option by default, according to which, unless you deactivate the auto-renewal option, such Services will automatically renew upon the end of the applicable subscription period, for a renewal period equal in time to the original subscription period (excluding extended periods) and, unless otherwise notified to you, at the same price (subject to applicable Taxes changes and excluding any discount or other promotional offer provided for the first period) (“Renewing Paid Services”). For example, if the original subscription period for a Service is one month, each of its renewal periods (where applicable) will be for one month. Accordingly, where applicable, Evala will attempt to automatically charge you within up-to two (2) weeks before such renewal period commences. In the event of failure to collect the Fees owed by you, we may in our sole discretion (but shall not be obligated to) retry to collect on a later time, and/or suspend or cancel your User Account, without further notice.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    By entering into this Agreement and by purchasing a Service, you acknowledge and agree that the Service shall automatically renew in accordance with the above terms.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    You may turn-off the auto-renewal option for Renewing Paid Services at any time by contacting us.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    You are and shall be solely responsible to verify and ensure the successful renewal of the Evala Services you use (whether or not such Evala Services are subject to automatic subscription renewals). Accordingly, you shall be solely responsible with respect to any discontinuation of any Evala Services previously purchased by you, including due to a cancellation, failure to charge the applicable recurring Fees, or due to any Evala Services not being subject to automatic subscription renewals. You acknowledge and agree that you shall not have any claims against Evala in relation to the discontinuation of any Evala Services or Third Party Services, for whatever reason.
                                </h5>
                            </li>
                        </ol>
                    </AnimateTopToBottom>
                    <AnimateTopToBottom delay={0.2}>

                        <h5 className={Classes.mini_title}> 5.4. Chargebacks </h5>
                        <ol>
                            <li>
                                <h5>
                                    If, at any time, we record a decline, chargeback or other rejection of a charge of any payable Fees on your Evala account (“Chargeback”), this will be considered as a breach of your payment obligations hereunder, and your use of the Evala Services may be automatically disabled or terminated.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    In the event a Chargeback is performed, your User Account may be blocked without the option to re-purchase or re-use it, and any data contained in such User Account, including any domains, applications and Third Party Services may be subject to cancellation and Capacity Loss (as defined in Section 6.3 below).
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    Your use of the Evala Services will not resume until you re-subscribe for any such Evala Services, and pay any applicable Fees in full, including any fees and expenses incurred by Evala and/or any Third Party Services for each Chargeback received (including Fees for Evala Services provided prior to the Chargeback, handling and processing charges and fees incurred by the payment processor).
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    If you have any questions or concerns regarding a payment made to Evala, we encourage you to first contact our Customer Support team before filing a Chargeback or reversal of payment, in order to prevent the Evala Services from being cancelled and your User Account being blocked, and to avoid the filing of an unwarranted or erroneous Chargeback, which may result in your being liable for its applicable Fees, in addition to re-payment of all the Fees applicable to the Evala Services purchased (and charged-back) by you.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    We reserve our right to dispute any Chargeback received, including by providing the relevant credit card company or financial institution with any information and documentation proving that the User responsible for such Chargeback did in fact authorize the transaction and make use of the services rendered thereafter.
                                </h5>
                            </li>
                        </ol>
                        <br />
                    </AnimateTopToBottom>
                    <AnimateTopToBottom delay={0.2}>

                        <h5 className={Classes.major_title}> 6. CANCELLATION </h5>
                        <br />

                        <h5 className={Classes.mini_title}> 6.1. Cancellation by User </h5>
                        <ol>
                            <li>
                                <h5>
                                    You may discontinue to use and request to cancel your User Account and/or any Evala Services at any time, in accordance with the instructions available on the Evala Services. The effective date and time for such cancellation shall be the date and time on which you have completed the cancellation process on the Evala Services, and the effective date for cancellation of Paid Services shall be at the end of such Paid Services’ subscription period.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    Notwithstanding anything to the contrary in the foregoing, with respect to subscriptions to Renewing Paid Services, such subscription will be discontinued only upon the expiration of the respective period for which you have already made payment. Please note that as the cancellation process may take a few days, in order to avoid the next automatic renewal and respective charge the cancellation request should be made at least fourteen (14) days prior to the expiration of the then-current service period.
                                </h5>
                            </li>
                        </ol>
                        <br />
                    </AnimateTopToBottom>
                    <AnimateTopToBottom delay={0.2}>

                        <h5 className={Classes.major_title}> 6.2. Cancellation by Evala </h5>
                        <br />
                        <h5>
                            Failure to comply with any of the Evala Terms and/or to pay any due Fee shall entitle Evala to suspend (until full payment is made) or cancel your User Account and User Platform (or certain features thereof), as well as the provision of any related Evala Services (e.g., Paid Services) or Third Party Services to you.
                        </h5>
                        <br />

                        <h5 className={Classes.major_title}> 6.3. Loss of Data, Content and Capacity </h5>
                        <br />
                        <h5>
                            If your User Account or any Evala Services or Third Party Services related to your User Account are cancelled (whether at your request or at Evala’s discretion), it may cause or result in the loss of certain content, features, or capacity of your User Account, including any User Content, or other usage data retained therein, and including any domain name reservation or registration that was included in such Services (“Capacity Loss”). Evala shall not be liable in any way for such Capacity Loss, or for saving a backup of your User Account, User Content or data. Please also note that additional Fees may apply to re-activation of a User Account and/or any Evala Services following their cancellation, as determined by Evala in its sole discretion.
                        </h5>
                        <br />
                    </AnimateTopToBottom>
                    <AnimateTopToBottom delay={0.2}>

                        <h5 className={Classes.major_title}> 7. THIRD PARTY SERVICES </h5>
                        <ol>
                            <li>
                                <h5>
                                    The Evala Services may enable you to engage and procure certain third party services, products and tools for enhancing your User Platform and your overall user experience (collectively, “Third Party Services”).
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    You acknowledge and agree that regardless of the manner in which such Third Party Services may be offered to you (bundled or integrated within certain Evala Services, offered separately by Evala or persons certified or authorized by Evala, or otherwise offered anywhere on the Evala Services), Evala merely acts as an intermediary platform between you and such Third Party Services, and does not in any way endorse any such Third Party Services, or shall be in any way responsible or liable with respect thereto. Evala will not be a party to, or in any way be responsible for monitoring, any interaction or transaction between you and any Third party Services.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    You acknowledge that such services may require the payment of additional amounts to Evala and/or to the providers of such Third-Party Services.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    Any and all use of such Third Party Services shall be done solely at your own risk and responsibility, and may be subject to such legal and financial terms which govern such Third Party Services, which you are encouraged to review before engaging with them.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    If you use third parties' services, software or goods while using our Services, you declare that you act in compliance with their terms of use.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    While we hope to avoid such instances, Evala may, at any time and at its sole discretion, suspend, disable access to or remove from your User Account, User Platform(s) and/or the Evala Services, any Third Party Services – whether or not incorporated with or made part of your User Account and/or User Platform(s) at such time – without any liability to you.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    Since we only act as an intermediary platform between you and such parties, only you will be responsible for your engagements with them.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    We can remove services at any time, which may affect your website, user account or overall experience.
                                </h5>
                            </li>
                        </ol>
                        <br />
                    </AnimateTopToBottom>
                    <AnimateTopToBottom delay={0.2}>
                        <h5 className={Classes.major_title}> 8. MISCONDUCT AND COPYRIGHTS </h5>
                        <br />
                        <h5 className={Classes.major_title}> 8.1. Misconduct and Abuse </h5>
                        <br />
                        <h5>
                            When using the Evala Services, you may be exposed to User Platforms, User Content or Third Party Services from a variety of sources, which may be inaccurate, offensive, objectionable or illegal. You hereby waive any legal or equitable rights or remedies you have or may have against Evala with respect thereto.
                        </h5>
                        <br />
                        <h5>
                            If you believe a User or any Third Party Services acted inappropriately or otherwise misused any of the Evala Services, please immediately report such User and/or Third Party Service to us via email. You agree that your report shall not impose any responsibility or liability upon Evala, and that Evala may consider such report and act upon it, refrain from taking any such action or require additional information or documents before doing so, at its sole discretion.
                        </h5>
                        <br />
                    </AnimateTopToBottom>
                    <AnimateTopToBottom delay={0.2}>

                        <h5 className={Classes.major_title}> 8.2. Copyrights </h5>
                        <ol>
                            <li>
                                <h5>
                                    Evala acts in accordance with its interpretation of the Digital Millennium Copyright Act (“DMCA”). If you believe that your work has been copied or was otherwise used in a way that constitutes copyright infringement, you may notify us of such infringement via email, or otherwise provide the following information in writing to our designated Copyright Agent: (1) the contact details of the person authorized to act on behalf of the owner of the copyright; (2) a description of the copyrighted work that you claim has been infringed; (3) a description of the material that you claim to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information sufficient to permit Evala to locate the material (including URL address); (4) a statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and (5) a statement that the information in the notification is accurate and, under penalty of perjury, that you are the copyright owner or are authorized to act on behalf of the owner of a copyright that is allegedly infringed.
                                </h5>
                            </li>
                        </ol>
                        <br />

                        <h5 className={Classes.major_title}> 9. DISCLAIMER OF WARRANTIES </h5>
                        <ol>
                            <li>
                                <h5>
                                    We provide the Evala Services on an “As Is”, “with all faults” and “As Available” basis, without any warranties of any kind, including any implied warranties or conditions of merchantability, fitness for a particular purpose, workmanlike effort, non-infringement, or any other warranty – all to the fullest extent permitted by law. We specifically do not represent or warrant that the Evala Services (or any part, feature or Content thereof) are complete, accurate, of any certain quality, reliable or secure in any way, suitable for or compatible with any of your contemplated activities, devices, operating systems, browsers, software or tools (or that they will remain as such at any time), or comply with any laws applicable to you (including in any jurisdiction in which you operate), or that their operation will be free of any viruses, bugs or other harmful components or program limitations. Moreover, we do not endorse any entity, product or service (including any Third Party Services) mentioned on or made available via the Evala Services – so please be sure to verify those before using or otherwise engaging them.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    Evala may, at its sole discretion (however it shall have no obligation to do so), screen, monitor and/or edit any User Platform and/or User Content, at any time and for any reason, with or without notice.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    Notwithstanding anything to the contrary in the foregoing, in no circumstances may Evala be considered as a “publisher” of any User Content, does not in any way endorse any User Content, and assumes no liability for any User Content uploaded, posted, published and/or made available by any User or any other party on and/or through the Evala Services, for any use by any party, or for any loss, deletion or damage thereto or thereof or any loss, damage, cost or expense that you or others may suffer or incur as a result of or in connection with publishing, accessing and/or relying on any User Content. Furthermore, Evala shall not be liable for any mistakes, defamation, libel, falsehoods, obscenity, pornography, incitement and/or any other unlawful and/or infringing User Content you or any other party may encounter.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    You acknowledge that there are risks in using the Evala Services and/or connecting and/or dealing with any Third Party Services through or in connection with Evala Services, and that Evala cannot and does not guarantee any specific outcomes from such use and/or interactions, and you hereby assume all such risks, liabilities and/or harm of any kind arising in connection with and/or resulting from such interactions. Such risks may include, among others, misrepresentation of information about and/or by Third Party Services and/or Licensed Content, breach of warranty and/or contract, violation of rights, and any consequent claims.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    Evala does not recommend the use of the Evala Services for hosting of personal content and shall not bear any security or integrity obligations or risks regarding breach or damage to any such content.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    Please note that certain Evala Services are currently offered in their BETA version, and undergoing BETA testing. You understand and agree that certain Evala Services may still contain software bugs, suffer disruptions and not operate as intended or designated. Your use of the Evala Services at this BETA stage signifies your agreement to participate in such Evala Services’ BETA testing.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    However, we are not to be considered a “publisher” of your content, we do not endorse your content, and will not be liable for any content used by you or anyone else.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    There are risks in using our services or anyone else’s services. You accept such risks.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    Some of our services are still in BETA, and may contain bugs or experience interruptions.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    We do not warrant and we exclude all liability in respect of the accuracy, legitimacy, completeness, or legality of any information accessed using our Services, and we exclude all liability of any kind of any transmission or receipt of any material of whatever nature.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    you should not rely on any information accessed using our Services to make a purchasing decision; you should make your own enquiries before forming your own opinion and taking an action based on any such information.
                                </h5>
                            </li>
                        </ol>
                        <br />
                    </AnimateTopToBottom>
                    <AnimateTopToBottom delay={0.2}>

                        <h5 className={Classes.major_title}> 10. LIMITATION OF LIABILITY </h5>
                        <ol>
                            <li>
                                <h5>
                                    To the fullest extent permitted by law in each applicable jurisdiction, Evala, its officers, directors, shareholders, employees, affiliates and/or agents shall not be liable to you for any direct, indirect, incidental, special, punitive, exemplary or consequential damages whatsoever, including any damages resulting from (1) errors, mistakes, or inaccuracies of or in any content; (2) any personal injury or property damage related to your use of the Evala Services; (3) any unauthorized access to or use of our servers and/or any personal information and/or other information stored therein; (4) any interruption or cessation of transmission to or from the Evala Services; (5) the use or display of any Content or User Content posted, emailed, transmitted, or otherwise made available via the Evala Services; (6) events beyond the reasonable control of Evala, including any internet failures, equipment failures, electrical power failures, strikes, labor disputes, riots, insurrections, civil disturbances, shortages of labor or materials, fires, floods, storms, earthquakes, explosions, acts of God, war, terrorism, intergalactic struggles, governmental actions, orders of courts, agencies or tribunals or non-performance of third parties; and/or (7) loss of use, data, profits, goodwill, or other intangible losses, resulting from the use or the inability to use any or all of Evala Services.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    You acknowledge and agree that these limitations of liability are agreed allocations of risk constituting in part the consideration for Evala’s services to you, and such limitations will apply even if Evala has been advised of the possibility of such liabilities.
                                </h5>
                            </li>
                        </ol>
                        <br />
                    </AnimateTopToBottom>
                    <AnimateTopToBottom delay={0.2}>

                        <h5 className={Classes.major_title}> 11. INDEMNITY </h5>
                        <br />
                        <h5>
                            You agree to defend, indemnify and hold harmless Evala, its officers, directors, shareholders, employees, affiliates and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs, debt and expenses (including attorneys’ fees) arising from: (1) your violation of any term of these Terms of Use or any other Evala Terms; (2) your violation of any third party right, including any copyright, access rights, property, or privacy right, resulting from your User Platform or User Content and/or your use of the Evala Services, including, without limitation, Evala Services’ actions for your benefit; and/or (3) any other type of claim that your User Platform and/or User Content caused damage to a third party.
                        </h5>
                        <br />

                        <h5 className={Classes.major_title}> 12. GENERAL </h5>
                        <br />
                        <h5 className={Classes.mini_title}> 12.1. Changes & Updates </h5>
                        <ol>
                            <li>
                                <h5>
                                    Evala reserves the right to change, suspend or terminate any of the Evala Services (or any features thereof, or prices applicable thereto), and/or cancel your access to any of the Evala Services (including removal of any materials created by you in connection with the Evala Services) for any reason and/or change any of the Evala Terms with or without prior notice - at any time and in any manner.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    You agree that Evala will not be liable to you or to any third party for any modification, suspension or discontinuance of those Evala Services.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    If any such changes involve the payment of additional Fees, we will provide you with a notice of such Fees prior to enabling such specific changes. If you fail or refuse to pay such Fees, we may (at our sole discretion) cancel your User Account (as further explained in Section 6 above), continue to support your then-current Evala Services without enabling such changes, or provide you with alternative Services.
                                </h5>
                            </li>
                        </ol>
                        <br />
                    </AnimateTopToBottom>
                    <AnimateTopToBottom delay={0.2}>

                        <h5 className={Classes.mini_title}> 12.2. Governing Law & Jurisdiction; Class Action Waiver </h5>
                        <ol>
                            <li>
                                <h5>
                                    The Evala Terms, the rights and remedies provided hereunder, and any and all claims and disputes related hereto and/or to the Evala Services, their interpretation, or the breach, termination or validity thereof, the relationships which result from or pursuant to the Evala Terms, or any related transaction or purchase, shall be governed by, construed under and enforced in all respects solely and exclusively in accordance with the internal substantive laws of the State of New York, without respect to its conflict of laws principles.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    Any and all such claims and disputes shall be brought in, and you hereby consent to them being decided exclusively by, a court of competent jurisdiction located in New York. The application of the United Nations Convention of Contracts for the International Sale of Goods is hereby expressly excluded.
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    Subject to any applicable law, all disputes between you and Evala shall only be resolved on an individual basis and you shall not have the right to bring any claim against Evala as a plaintiff or a member of a class, consolidated or representative actions (or any other legal proceedings conducted by a group or by representatives on behalf of others).
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    Notwithstanding anything to the contrary in this Section 13.2, if you are located in the United States of America, (i) the legal jurisdiction governing all aspects of the payment transaction contemplated by Section 5 between you and Evala will be the State of New York, without respect to its conflict of laws principles, and (ii) any and all claims and disputes related to such payment transaction contemplated by Section 5 shall be brought in, and you hereby consent to them being decided exclusively by, a court of competent jurisdiction located in New York.
                                </h5>
                            </li>
                        </ol>
                    </AnimateTopToBottom>
                    <AnimateTopToBottom delay={0.2}>

                        <h5 className={Classes.mini_title} > 12.3. Notices </h5>
                        <br />
                        <h5>
                            We may provide you with notices in any of the following methods: (1) via the Evala Services, including by a banner or pop-up within the Evala Website, User Account or elsewhere; (2) by email, sent to the e-mail address you provided us; and/or (3) through any other means, including any phone number or physical address you provided us. Evala’s notice to you will be deemed received and effective within twenty four (24) hours after it was published or sent through any of the foregoing methods, unless otherwise indicated in the notice.
                        </h5>
                        <br />

                        <h5 className={Classes.mini_title} > 12.4. Relationship </h5>
                        <br />
                        <h5>
                            The Evala Terms, and your use of the Evala Services, do not, and shall not be construed to create any partnership, joint venture, employer-employee, agency, or franchisor-franchisee relationship between Evala and you.
                        </h5>
                        <br />

                        <h5 className={Classes.mini_title} > 12.5. Entire Agreement </h5>
                        <br />
                        <h5>
                            These Terms of Use, together with the Evala Terms and any other legal or fee notices provided to you by Evala, shall constitute the entire agreement between you and Evala concerning the subject matter hereof or thereof, and supersede any and all prior or contemporaneous agreements, understandings, promises, conditions, negotiations, covenants or representations, whether written or oral, between Evala and you, including those made by or between any of our respective representatives, with respect to any of the Evala Services. You further agree that you are not relying upon any promise, inducement, representation, statement, disclosure or duty of disclosure of Evala in entering into any of the Evala Terms.
                        </h5>
                        <br />

                        <h5 className={Classes.mini_title} > 12.6. Assignment </h5>
                        <br />
                        <h5>
                            Evala may assign its rights and/or obligations hereunder and/or transfer ownership rights and title in the Evala Services and/or Content to a third party without your consent or prior notice to you. You may not assign or transfer any of your rights and obligations hereunder without the prior written consent of Evala. Any attempted or actual assignment thereof without Evala’s prior explicit and written consent will be null and void. In any event, an assignment or transfer pursuant to this Section ‎13.6 shall not in itself grant either Evala or you the right to cancel any Evala Services or Third Party Services then in effect.
                        </h5>
                        <br />
                    </AnimateTopToBottom>
                    <AnimateTopToBottom delay={0.2}>

                        <h5 className={Classes.mini_title} > 12.7. Severability & Waivers </h5>
                        <br />
                        <h5>
                            If any provision of the Evala Terms is deemed by a court of competent jurisdiction to be invalid, unlawful, void, or for any reason unenforceable, then such provision shall be deemed severable and will not affect the validity and enforceability of the remaining provisions. No Waiver of any breach or default of any of the Evala Terms shall be deemed to be a waiver of any preceding or subsequent breach or default.                    </h5>
                        <br />

                        <h5 className={Classes.mini_title} > 12.8. Customer Service Contact </h5>
                        <br />
                        <h5>
                            To get in touch with our Customer Service - please send an email message to: <a href='/contact-us' > support@evala.ai,</a>
                        </h5>
                        <br />
                    </AnimateTopToBottom>
                </div>
            </form>
        </>
    )
}

export default TermsOfService
